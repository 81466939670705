const listOfAdminsSupervisors = [
  {
    id: 1,
    name: "John Doe",
    email: "johndoe@example.com",
    role: "supervisor",
    zone: "Adamawa Central",
    phoneNumber: "1234567890",
  },
  {
    id: 2,
    name: "Jane Smith",
    email: "janesmith@example.com",
    role: "admin",
    zone: "Adamawa North",
    phoneNumber: "9876543210",
  },
  {
    id: 3,
    name: "Alice Johnson",
    email: "alicejohnson@example.com",
    role: "admin",
    zone: "Adamawa South",
    phoneNumber: "4567890123",
  },
  {
    id: 4,
    name: "Michael Brown",
    email: "michaelbrown@example.com",
    role: "supervisor",
    zone: "Adamawa Central",
    phoneNumber: "9876543210",
  },
  {
    id: 5,
    name: "Sarah Wilson",
    email: "sarahwilson@example.com",
    role: "admin",
    zone: "Adamawa North",
    phoneNumber: "1234567890",
  },
  {
    id: 6,
    name: "David Thompson",
    email: "davidthompson@example.com",
    role: "supervisor",
    zone: "Adamawa South",
    phoneNumber: "7890123456",
  },
  {
    id: 7,
    name: "Emily Davis",
    email: "emilydavis@example.com",
    role: "admin",
    zone: "Adamawa Central",
    phoneNumber: "4567890123",
  },
  {
    id: 8,
    name: "Daniel Clark",
    email: "danielclark@example.com",
    role: "admin",
    zone: "Adamawa North",
    phoneNumber: "7890123456",
  },
  {
    id: 9,
    name: "Olivia Wilson",
    email: "oliviawilson@example.com",
    role: "supervisor",
    zone: "Adamawa South",
    phoneNumber: "1234567890",
  },
  {
    id: 10,
    name: "Matthew Green",
    email: "matthewgreen@example.com",
    role: "admin",
    zone: "Adamawa Central",
    phoneNumber: "9876543210",
  },
  {
    id: 11,
    name: "Sophia Martinez",
    email: "sophiamartinez@example.com",
    role: "supervisor",
    zone: "Adamawa North",
    phoneNumber: "7890123456",
  },
  {
    id: 12,
    name: "Jacob Anderson",
    email: "jacobanderson@example.com",
    role: "admin",
    zone: "Adamawa South",
    phoneNumber: "1234567890",
  },
  {
    id: 13,
    name: "Emma Johnson",
    email: "emmajohnson@example.com",
    role: "admin",
    zone: "Adamawa Central",
    phoneNumber: "4567890123",
  },
  {
    id: 14,
    name: "Alexander Davis",
    email: "alexanderdavis@example.com",
    role: "supervisor",
    zone: "Adamawa North",
    phoneNumber: "9876543210",
  },
  {
    id: 15,
    name: "Ava Thompson",
    email: "avathompson@example.com",
    role: "admin",
    zone: "Adamawa South",
    phoneNumber: "7890123456",
  },
];

export default listOfAdminsSupervisors;
